import {fabric} from "fabric";
import {v4 as uuidv4} from "uuid";

export function copyActiveObjects() {
    const objects = this.getObjectsFromTarget(this.fabricCanvas.getActiveObject());
    if (objects.length !== 0) {
        const group = this.normalizeObject(new fabric.Group(objects));
        group.set({top: 0, left: 0});
        const blob = new Blob([JSON.stringify({groupToInsert: group})], {type: 'text/plain'});
        const item = new ClipboardItem({'text/plain': blob});
        navigator.clipboard.write([item]).then(() => {}).catch(err => {
            console.error(err);
        });
    }
}

export function pasteObjects() {
    navigator.clipboard.read().then(data => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].types.includes('text/plain')) {
                data[i].getType('text/plain').then(blob => {
                    blob.text().then(text => {
                        try {
                            const obj = JSON.parse(text);
                            if (Object.keys(obj).length !== 1 || !obj.groupToInsert) {
                                return;
                            }
                            fabric.util.enlivenObjects([obj.groupToInsert], groups => {
                                groups.forEach(group => {
                                    group = this.denormalizeObject(group);
                                    group.set({
                                        top: this.prevMouse.y || 0,
                                        left: this.prevMouse.x || 0,
                                    });
                                    const objects = group.getObjects();
                                    group.destroy(); // ungroup objects
                                    objects.forEach(object => {
                                        object.set({uuid: uuidv4()});
                                        this.registerObjectOnBoard(object);
                                        this.fabricCanvas.add(object);
                                        this.sendObjectAddition(object);
                                    });
                                });
                                this.fabricCanvas.requestRenderAll();
                            });
                        } catch (e) {
                            console.error(e);
                        }
                    })
                });
            }
        }
    });
}
