import {fabric} from "fabric";

export function startText() {
    if (this.commonSettings.mode) {
        this.stopPreviousMode();
    }
    this.commonSettings.mode = 'text';
    this.fabricCanvas.defaultCursor = 'text';
    this.fabricCanvas.hoverCursor = 'text';
    this.fabricCanvas.selection = false;
    this.fabricCanvas.on('mouse:down', (evt) => {
        if (this.fabricCanvas.getActiveObject() && !this.fabricCanvas.getActiveObject().text) {
            this.fabricCanvas.getActiveObject().selectable = false;
            this.fabricCanvas.getActiveObject().evented = false;
        }
        if (!this.fabricCanvas.getActiveObject() || (this.fabricCanvas.getActiveObject() && !this.fabricCanvas.getActiveObject().text)) {
            let text = new fabric.IText(' ', {
                fontFamily: 'Helvetica',
                left: evt.absolutePointer.x,
                top: evt.absolutePointer.y,
                fontSize: 24 * this.fabricCanvas.width / 1111,
                fill: this.getColor(),
            });
            text.selectionStart = 0;
            text.selectionEnd = text.text.length;
            this.registerObjectOnBoard(text);
            this.fabricCanvas.add(text).setActiveObject(text);
            text.enterEditing();
            text.hiddenTextarea.focus();
            this.fabricCanvas.requestRenderAll();
            this.sendObjectAddition(text);
        }
        if (this.fabricCanvas.getActiveObject() && this.fabricCanvas.getActiveObject().hiddenTextarea) {
            this.fabricCanvas.getActiveObject().hasControls = true;
            this.fabricCanvas.getActiveObject().hasBorders = true;
            this.fabricCanvas.getActiveObject().lockMovementX = true;
            this.fabricCanvas.getActiveObject().lockMovementY = true;
            this.fabricCanvas.getActiveObject().lockUniScaling = true;
            this.fabricCanvas.requestRenderAll();
        }
        this.startSelecting();
    });
}

export function stopText(nextChosen = false) {
    this.fabricCanvas.defaultCursor = 'default';
    this.fabricCanvas.hoverCursor = 'default';
    this.fabricCanvas.__eventListeners['mouse:down'].pop();
    this.commonSettings.mode = null;
    if (!nextChosen) {
        this.startSelecting();
    }
}
