import {fabric} from "fabric";

export function addImageByURL(url) {
    fabric.Image.fromURL(url, img => {
        const heightCoef = img.height / (this.fabricCanvas.height * 0.9);
        const widthCoef = img.width / (this.fabricCanvas.width * 0.9);
        if (heightCoef > 1 && heightCoef > widthCoef) {
            img.scaleToHeight(Math.floor(this.fabricCanvas.height * 0.9));
        } else if (widthCoef > 1 && widthCoef > heightCoef) {
            img.scaleToWidth(Math.floor(this.fabricCanvas.width * 0.9));
        }
        this.registerObjectOnBoard(img);
        this.fabricCanvas.add(img).setActiveObject(img).requestRenderAll();
        this.sendObjectAddition(img);
    });
}
