export function getStrokeOfSelected() {
    const objects = this.fabricCanvas.getActiveObjects();
    if (objects.length === 0) {
        return {
            shouldShow: false,
            color: '',
        };
    }
    let stroke = {
        shouldShow: true,
        color: '',
    };
    for (const [idx, object] of objects.entries()) {
        if (object.type === 'image' || object.type === 'i-text') {
            stroke.shouldShow = false;
            stroke.color = '';
            break;
        }
        if (idx === 0) {
            stroke.color = object.stroke;
        } else if (object.stroke !== stroke.color) {
            stroke.color = '';
            break;
        }
    }
    return stroke;
}

export function setStrokeColorToSelected(color) {
    const target = this.fabricCanvas.getActiveObject();
    if (!target) {
        return;
    }
    if (target.type === 'activeSelection') {
        for (const object of target.getObjects()) {
            this.objectsByUUID[object.uuid].set('stroke', color);
        }
    } else {
        target.set('stroke', color);
    }
    this.selectionParams.stroke.color = color;
    this.fabricCanvas.requestRenderAll();
    this.fabricCanvas.fire('object:modified', {target: target});
}
