export function registerObjectAdditionSender(sender) {
    this.objectAdditionSender = sender;
}

export function registerObjectTransformSender(sender) {
    this.objectTransformSender = sender;
}

export function registerObjectDeletionSender(sender) {
    this.objectDeletionSender = sender;
}

export function registerMouseMoveSender(sender) {
    this.mouseMoveSender = sender;
}

export function sendObjectAddition(object, isNewAction = true) {
    if (isNewAction) {
        this.history.newAction({
            type: 'addition',
            payload: JSON.stringify(object),
        });
    }
    if (this.objectAdditionSender) {
        this.objectAdditionSender(this.normalizeObject(object));
    }
}

export function sendObjectTransform(objects, isNewAction = true) {
    if (isNewAction) {
        let transformations = [];
        for (let object of objects) {
            transformations.push([this.objectsJSON[object.uuid], JSON.stringify(object)]);
            this.objectsJSON[object.uuid] = JSON.stringify(object);
        }
        this.history.newAction({
            type: 'transform',
            payload: transformations,
        });
    }
    if (this.objectTransformSender) {
        this.objectTransformSender(this.normalizeManyObjects(objects));
    }
}

export function sendObjectDeletion(uuids, isNewAction = true) {
    if (isNewAction) {
        let objects = [];
        for (let uuid of uuids) {
            objects.push(this.objectsJSON[uuid]);
        }
        this.history.newAction({
            type: 'deletion',
            payload: objects,
        });
    }
    if (this.objectDeletionSender) {
        this.objectDeletionSender(uuids);
    }
}

export function sendMouseMove(x, y) {
    if (this.mouseMoveSender) {
        this.mouseMoveSender(x / this.fabricCanvas.width, y / this.fabricCanvas.width);
    }
}
