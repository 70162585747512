import {MODE_DRAWING} from "@/components/MaterialsV2/BoardCellV2/canvas/const";
import {reactive} from "vue";

export function setDefaultCommonSettings() {
    this.commonSettings = reactive({
        mode: null,
        brushWidth: 2,
        color: 'black',
    });
}

export function getBrushWidth() {
    return Math.floor(this.commonSettings.brushWidth * this.fabricCanvas.width / 1111 * 100) / 100;
}

export function getColor() {
    return this.commonSettings.color;
}

export function setColor(newColor) {
    this.commonSettings.color = newColor;
    if (this.commonSettings.mode === MODE_DRAWING) {
        this.fabricCanvas.freeDrawingBrush.color = this.commonSettings.color;
    }
}

export function changeBrushWidth(delta) {
    if (this.commonSettings.brushWidth + delta <= 0 || this.commonSettings.brushWidth + delta > 30) {
        return;
    }
    this.commonSettings.brushWidth += delta
    if (this.commonSettings.mode === MODE_DRAWING) {
        this.fabricCanvas.freeDrawingBrush.width = this.commonSettings.brushWidth;
    }
}