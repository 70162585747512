import {isMobile} from "@/util/is-mobile";
import {MODE_ERASING} from "@/components/MaterialsV2/BoardCellV2/canvas/const";

export function startErasing() {
    if (this.commonSettings.mode) {
        this.stopPreviousMode();
    }
    this.commonSettings.mode = MODE_ERASING;
    this.fabricCanvas.defaultCursor = 'crosshair';
    this.fabricCanvas.hoverCursor = 'crosshair';
    this.fabricCanvas.selection = false;
    if (!isMobile()) {
        this.fabricCanvas.on('mouse:down', (evt) => {
            let curMouse = {
                x: Math.round(evt.pointer.x),
                y: Math.round(evt.pointer.y),
            };
            this.erasing = true;
            this.performEraseOnPoint(curMouse.x, curMouse.y);
            this.prevEraser.x = curMouse.x;
            this.prevEraser.y = curMouse.y;
        });
        this.fabricCanvas.on('mouse:move', (evt) => {
            let curMouse = {
                x: Math.round(evt.pointer.x),
                y: Math.round(evt.pointer.y),
            };
            if (curMouse.x === this.prevEraser.x && curMouse.y === this.prevEraser.y) {
                return;
            }
            if (this.erasing) {
                this.performErase(this.prevEraser, curMouse);
            }
            this.prevEraser.x = curMouse.x;
            this.prevEraser.y = curMouse.y;
        });
        this.fabricCanvas.on('mouse:up', (evt) => {
            this.erasing = false;
        });
    } else {
        this.fabricCanvas.on('touch:drag', evt => {
            if (evt.e.hasOwnProperty('changedTouches')) {
                this.prevEraser.x = null;
                this.prevEraser.y = null;
            } else if (evt.self && evt.self.x !== undefined && evt.self.y !== undefined) {
                let curPos = {
                    x: evt.self.x,
                    y: evt.self.y,
                }
                if (curPos.x === this.prevEraser.x && curPos.y === this.prevEraser.y) {
                    return;
                }
                if (this.prevEraser.x !== null) {
                    this.performErase(this.prevEraser, curPos);
                } else {
                    this.performEraseOnPoint(curPos);
                }
                this.prevEraser.x = curPos.x;
                this.prevEraser.y = curPos.y;
            }
        });
    }
    this.makeObjectsUnselectable();
}

export function stopErasing(nextChosen = false) {
    this.fabricCanvas.defaultCursor = 'default';
    this.fabricCanvas.hoverCursor = 'default';
    if (!isMobile()) {
        this.fabricCanvas.__eventListeners['mouse:down'].pop();
        this.fabricCanvas.__eventListeners['mouse:move'].pop();
        this.fabricCanvas.__eventListeners['mouse:up'].pop();
    } else {
        this.fabricCanvas.__eventListeners['touch:drag'].pop();
    }
    this.makeObjectsSelectable();
    this.commonSettings.mode = null;
    if (!nextChosen) {
        this.startSelecting();
    }
}

export function performErase(from, to) {
    let dx = to.x - from.x;
    let dy = to.y - from.y;
    let steps = Math.ceil(Math.sqrt(dx * dx + dy * dy) / 3);
    let stepX = dx / steps;
    let stepY = dy / steps;
    for (let i = 1; i < steps; i++) {
        this.performEraseOnPoint(from.x + i * stepX, from.y + i * stepY);
    }
    this.performEraseOnPoint(to.x, to.y);
}

export function performEraseOnPoint(x, y) {
    for (let object of Object.values(this.objectsByUUID)) {
        if (object.type !== 'image' && !this.fabricCanvas.isTargetTransparent(object, x, y)) {
            this.sendObjectDeletion([object.uuid]);
            this.removeObject(object.uuid);
        }
    }
}
