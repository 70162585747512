import {fabric} from "fabric";
import {isMobile} from "@/util/is-mobile";
import {MODE_CIRCLE} from "@/components/MaterialsV2/BoardCellV2/canvas/const";

export function drawCircle(x, y) {
    this.makeObjectsUndraggable();
    let circle = new fabric.Ellipse({
        left: x,
        top: y,
        start_x: x,
        start_y: y,
        rx: this.getBrushWidth(),
        ry: this.getBrushWidth(),
        fill: 'transparent',
        stroke: this.getColor(),
        strokeWidth: this.getBrushWidth(),
        noScaleCache: false,
    });
    this.fabricCanvas.add(circle);
    this.registerObjectOnBoard(circle);
    this.curCircleId = circle.uuid;
}

export function drawingCircle(circle, x, y, shiftKey = false) {
    let new_rx = Math.abs(x - circle.start_x) / 2;
    let new_ry = Math.abs(y - circle.start_y) / 2;
    if (shiftKey) {
        new_rx = new_ry = Math.min(new_rx, new_ry);
    }
    circle.set({
        left: Math.min(x, circle.start_x),
        top: Math.min(y, circle.start_y),
        rx: new_rx,
        ry: new_ry,
    });
    circle.setCoords();
    this.fabricCanvas.requestRenderAll();
}

export function finishDrawCircle() {
    if (!this.curCircleId) {
        return;
    }
    const circle = this.objectsByUUID[this.curCircleId];
    if (circle.rx > this.getBrushWidth() + 0.001 || circle.ry > this.getBrushWidth() + 0.001) {
        this.fabricCanvas.setActiveObject(circle);
        this.sendObjectAddition(circle);
    } else {
        this.fabricCanvas.remove(circle);
    }
    this.fabricCanvas.requestRenderAll();
    this.curCircleId = null;
    this.makeObjectsDraggable();
}

export function startCircle() {
    if (this.commonSettings.mode) {
        this.stopPreviousMode();
    }
    this.commonSettings.mode = MODE_CIRCLE;
    this.fabricCanvas.defaultCursor = this.fabricCanvas.hoverCursor = 'crosshair';
    if (!isMobile()) {
        this.fabricCanvas.on('mouse:down', evt => { this.drawCircle(evt.pointer.x, evt.pointer.y); });
        this.fabricCanvas.on('mouse:move', evt => {
            if (this.scalingNow || this.rotatingNow) {
                this.finishDrawCircle();
                return;
            }
            if (this.curCircleId) {
                const circle = this.objectsByUUID[this.curCircleId];
                this.drawingCircle(circle, evt.pointer.x, evt.pointer.y, evt.e.shiftKey);
            }
        });
        this.fabricCanvas.on('mouse:up', () => { this.finishDrawCircle() });
    } else {
        this.fabricCanvas.on('touch:drag', (evt) => {
            if (this.scalingNow || this.rotatingNow) {
                this.finishDrawCircle();
                return;
            }
            if (evt.e.hasOwnProperty('changedTouches')) {
                this.finishDrawCircle();
            } else if (evt.self.x !== undefined && evt.self.y !== undefined) {
                if (!this.curCircleId) {
                    this.drawCircle(evt.self.x, evt.self.y);
                }
                const circle = this.objectsByUUID[this.curCircleId];
                this.drawingCircle(circle, evt.self.x, evt.self.y);
            }
        });
    }
}

export function stopCircle(nextChosen = false) {
    this.fabricCanvas.defaultCursor = this.fabricCanvas.hoverCursor = 'default';
    if (!isMobile()) {
        this.fabricCanvas.__eventListeners['mouse:down'].pop();
        this.fabricCanvas.__eventListeners['mouse:move'].pop();
        this.fabricCanvas.__eventListeners['mouse:up'].pop();
    } else {
        this.fabricCanvas.__eventListeners['touch:drag'].pop();
    }
    this.commonSettings.mode = null;
    if (!nextChosen) {
        this.startSelecting();
    }
}

export function scaleCircle(circle) {
    circle.set({
        rx: circle.rx * circle.scaleX,
        ry: circle.ry * circle.scaleY,
        scaleX: 1,
        scaleY: 1,
    });
}
