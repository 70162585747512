import {fabric} from "fabric";

export function makeCursor(color, name) {
    let circle = new fabric.Circle({
        radius: 5,
        fill: color,
    });
    let text = new fabric.IText(name, {
        top: 20,
        left: 10,
        fontSize: 12,
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        fontWeight: 'bold',
    });
    let rect = new fabric.Rect({
        width: text.width * 1.125 + 12,
        height: text.height + 6,
        fill: 'white',
        top: 17,
        left: 4,
        rx: 10,
        ry: 10,
    });
    let group = new fabric.Group([ circle, rect, text ], {
        left: 100,
        top: 100,
        selectable: false,
        cornerSize: 7,
        hoverCursor: 'default',
    });
    group.is_cursor = true;
    this.usersOnBoard[name] = {
        cursor: group,
    };
    this.fabricCanvas.add(group).requestRenderAll();
}

export function removeCursor(username) {
    this.fabricCanvas.remove(this.usersOnBoard[username].cursor);
    delete this.usersOnBoard[username];
    this.fabricCanvas.requestRenderAll();
}

export function renderCursors(target_username = null) {
    let vpt = this.fabricCanvas.viewportTransform;
    const left = -vpt[4] / vpt[0];
    const right = (-vpt[4] + this.fabricCanvas.width - 30) / vpt[0];
    const top = -vpt[5] / vpt[0];
    const bottom = (-vpt[5] + this.fabricCanvas.height - 40) / vpt[0];
    if (target_username) {
        this.renderCursor(target_username, left, right, top, bottom, 1 / vpt[0], true);
        return;
    }
    for (const username in this.usersOnBoard) {
        this.renderCursor(username, left, right, top, bottom, 1 / vpt[0]);
    }
}

export function renderCursor(username, left, right, top, bottom, scale, animate = false) {
    let obj = this.usersOnBoard[username].cursor;
    let new_left = Math.min(Math.max(this.usersOnBoard[username].x, left), right);
    let new_top = Math.min(Math.max(this.usersOnBoard[username].y, top), bottom);
    if (obj.left !== new_left || obj.top !== new_top) {
        if (animate) {
            obj.animate({
                left: new_left,
                top: new_top,
            }, {
                onChange: this.fabricCanvas.renderAll.bind(this.fabricCanvas),
                duration: 100,
            });
        } else {
            obj.set({
                left: new_left,
                top: new_top,
            });
            obj.setCoords();
        }
    }
    if (obj.scaleX !== scale) {
        obj.set({
            scaleX: scale,
            scaleY: scale,
        });
    }
    this.fabricCanvas.moveTo(obj, 100);
}

const colors = ['darkviolet', 'purple', 'lime', 'cyan', 'navy', 'tomato', 'orangered',
    'dodgerblue', 'aqua', 'fuchsia', 'coral', 'hotpink', 'lightseagreen', 'salmon'];

export function getRandomColor() {
    return colors[Math.floor(Math.random() * colors.length)];
}
