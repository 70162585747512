export function getLockedOfSelected() {
    const objects = this.fabricCanvas.getActiveObjects();
    if (objects.length === 0) {
        return {
            shouldShow: false,
            locked: false,
        };
    }
    let lockState = {
        shouldShow: true,
        locked: false,
    };
    for (const object of objects) {
        if (!!object.locked) {
            lockState.locked = true;
            break;
        }
    }
    return lockState;
}

export function setLockedToSelected(value) {
    const target = this.fabricCanvas.getActiveObject();
    if (!target) {
        return;
    }
    if (target.type === 'activeSelection') {
        for (const object of target.getObjects()) {
            this.setLockStatus(this.objectsByUUID[object.uuid], value);
        }
    } else {
        this.setLockStatus(this.objectsByUUID[target.uuid], value);
    }
    this.selectionParams.lockState.locked = value;
    this.fabricCanvas.requestRenderAll();
    this.fabricCanvas.fire('object:modified', {target: target});
}

export function setLockStatus(object, value) {
    if (value) {
        object.set({
            locked: true,
            lockMovementX: true,
            lockMovementY: true,
            lockUniScaling: true,
        });
        object.setControlsVisibility({
           mt: false, mb: false, ml: false, mr: false,
           tl: false, tr: false, bl: false, br: false,
           mtr: false,
        });
    } else {
        object.set({
            locked: undefined,
            lockMovementX: !this.draggable,
            lockMovementY: !this.draggable,
            lockUniScaling: !this.scaleable,
        });
        object.setControlsVisibility({
            mt: this.scaleable, mb: this.scaleable, ml: this.scaleable, mr: this.scaleable,
            tl: this.scaleable, tr: this.scaleable, bl: this.scaleable, br: this.scaleable,
            mtr: this.scaleable,
        });
    }
}
