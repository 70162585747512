import {
    MODE_CIRCLE,
    MODE_DRAWING,
    MODE_ERASING,
    MODE_LINE,
    MODE_RECTANGLE,
    MODE_SELECTING,
    MODE_TEXT
} from "@/components/MaterialsV2/BoardCellV2/canvas/const";

export function stopPreviousMode() {
    switch (this.commonSettings.mode) {
        case MODE_SELECTING:
            this.stopSelecting(true);
            break;
        case MODE_DRAWING:
            this.stopDrawing(true);
            break;
        case MODE_ERASING:
            this.stopErasing(true);
            break;
        case MODE_LINE:
            this.stopLine(true);
            break;
        case MODE_RECTANGLE:
            this.stopRectangle(true);
            break;
        case MODE_CIRCLE:
            this.stopCircle(true);
            break;
        case MODE_TEXT:
            this.stopText(true);
            break;
    }
}
