import {fabric} from "fabric";

export function performUndo() {
    const action = this.history.goBackwards();
    if (!action) {
        return;
    }
    this.fabricCanvas.discardActiveObject();
    switch (action.type) {
        case 'addition':
            this.undoAddition(action.payload);
            break;
        case 'transform':
            this.undoTransform(action.payload);
            break;
        case 'deletion':
            this.undoDeletion(action.payload);
            break;
    }
}

export function performRedo() {
    const action = this.history.goForward();
    if (!action) {
        return;
    }
    this.fabricCanvas.discardActiveObject();
    switch (action.type) {
        case 'addition':
            this.redoAddition(action.payload);
            break;
        case 'transform':
            this.redoTransform(action.payload);
            break;
        case 'deletion':
            this.redoDeletion(action.payload);
            break;
    }
}

export function undoAddition(action) {
    let object = JSON.parse(action);
    this.sendObjectDeletion([object.uuid], false);
}

export function undoTransform(action) {
    let objects = [];
    for (let object of action) {
        objects.push(JSON.parse(object[0]));
    }
    let result = [];
    fabric.util.enlivenObjects(objects, objects => {
        objects.forEach(obj => {
            this.extendToObjectMethod(obj);
            result.push(obj);
        });
    });
    this.sendObjectTransform(result, false);
}

export function undoDeletion(action) {
    let objects = [];
    for (const [_, object] of Object.entries(action)) {
        objects.push(JSON.parse(object));
    }
    fabric.util.enlivenObjects(objects, objects => {
        objects.forEach(obj => {
            this.extendToObjectMethod(obj);
            this.sendObjectAddition(obj, false);
        });
    });
}

export function redoAddition(action) {
    let object = JSON.parse(action);
    fabric.util.enlivenObjects([object], objects => {
        objects.forEach(obj => {
            this.extendToObjectMethod(obj);
            this.sendObjectAddition(obj, false);
        });
    })
}

export function redoTransform(action) {
    let objects = [];
    for (let object of action) {
        objects.push(JSON.parse(object[1]));
    }
    let result = [];
    fabric.util.enlivenObjects(objects, objects => {
        objects.forEach(obj => {
            this.extendToObjectMethod(obj);
            result.push(obj);
        });
    });
    this.sendObjectTransform(result, false);
}

export function redoDeletion(action) {
    let uuids = [];
    for (const [_, object] of Object.entries(action)) {
        uuids.push(JSON.parse(object).uuid);
    }
    this.sendObjectDeletion(uuids, false);
}
