export function getFillOfSelected() {
    const objects = this.fabricCanvas.getActiveObjects();
    if (objects.length === 0) {
        return {
            shouldShow: false,
            color: '',
        };
    }
    let fill = {
        shouldShow: true,
        color: '',
    };
    for (const [idx, object] of objects.entries()) {
        if (object.type === 'line' || object.type === 'image' || object.type === 'i-text') {
            fill.shouldShow = false;
            fill.color = '';
            break;
        }
        if (idx === 0) {
            fill.color = object.fill;
        } else if (object.fill !== fill.color) {
            fill.color = '';
            break;
        }
    }
    return fill;
}

export function setFillColorToSelected(color) {
    const target = this.fabricCanvas.getActiveObject();
    if (!target) {
        return;
    }
    if (target.type === 'activeSelection') {
        for (const object of target.getObjects()) {
            this.objectsByUUID[object.uuid].set('fill', color);
        }
    } else {
        target.set('fill', color);
    }
    this.selectionParams.fill.color = color;
    this.fabricCanvas.requestRenderAll();
    this.fabricCanvas.fire('object:modified', {target: target});
}
