export function makeObjectsUnselectable() {
    this.fabricCanvas.forEachObject(object => {
        object.selectable = false;
    });
    this.selectable = false;
}

export function makeObjectsSelectable() {
    this.fabricCanvas.forEachObject(object => {
        if (!object.is_cursor) {
            object.selectable = true;
        }
    });
    this.selectable = true;
}

export function makeObjectsUndraggable() {
    this.fabricCanvas.forEachObject((object) => {
        object.lockMovementX = true;
        object.lockMovementY = true;
    });
    this.draggable = false;
}

export function makeObjectsDraggable() {
    this.fabricCanvas.forEachObject((object) => {
        if (!object.is_cursor && !object.locked) {
            object.lockMovementX = false;
            object.lockMovementY = false;
        }
    });
    this.draggable = true;
}

export function makeObjectsUnscaleable() {
    this.fabricCanvas.forEachObject((object) => {
        object.lockUniScaling = true;
        object.setControlsVisibility({
            mt: false, mb: false, ml: false, mr: false,
            tl: false, tr: false, bl: false, br: false,
            mtr: false,
        });
    });
    this.scaleable = false;
}

export function makeObjectsScaleable() {
    this.fabricCanvas.forEachObject((object) => {
        if (!object.is_cursor && !object.locked) {
            object.lockUniScaling = false;
            object.setControlsVisibility({
                mt: true, mb: true, ml: true, mr: true,
                tl: true, tr: true, bl: true, br: true,
                mtr: true,
            });
        }
    });
    this.scaleable = true;
}
