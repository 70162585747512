import {fabric} from "fabric";

export function normalizeObject(object) {
    let new_object = fabric.util.object.clone(object);
    new_object.set({
        top: object.top / this.fabricCanvas.width,
        left: object.left / this.fabricCanvas.width,
        scaleX: object.scaleX / this.fabricCanvas.width,
        scaleY: object.scaleY / this.fabricCanvas.width,
    });
    this.extendToObjectMethod(new_object);
    return new_object;
}

export function normalizeManyObjects(objects) {
    let result = [];
    for (const object of objects) {
        result.push(this.normalizeObject(object));
    }
    return result;
}

export function denormalizeObject(object) {
    let new_object = object;
    new_object.set({
        top: object.top * this.fabricCanvas.width,
        left: object.left * this.fabricCanvas.width,
        scaleX: object.scaleX * this.fabricCanvas.width,
        scaleY: object.scaleY * this.fabricCanvas.width,
    });
    return new_object;
}

export function extendToObjectMethod(object) {
    object.toObject = (function(toObject) {
        return function() {
            return fabric.util.object.extend(toObject.call(this), {
                uuid: this.uuid,
                scaleX: this.scaleX,
                scaleY: this.scaleY,
                top: this.top,
                left: this.left,
                locked: this.locked,
            });
        };
    })(object.toObject);
}

// returns an array of copies of the objects in the target
export function getObjectsFromTarget(target) {
    if (!target) {
        return [];
    }
    if (target.uuid) {
        return [fabric.util.object.clone(this.objectsByUUID[target.uuid])];
    }
    const targetObjects = target.getObjects();
    this.fabricCanvas.discardActiveObject();
    const objs = targetObjects.map(o => fabric.util.object.clone(this.objectsByUUID[o.uuid]));
    this.fabricCanvas.setActiveObject(new fabric.ActiveSelection(targetObjects, { canvas: this.fabricCanvas }));
    return objs;
}
