import {reactive} from "vue";

export function setContextMenu() {
    this.contextMenuSettings = reactive({
        component: null,
        show: false,
    });
    this.selectionParams = reactive({
        stroke: {
            shouldShow: false,
            color: '',
        },
        fill: {
            shouldShow: false,
            color: '',
        },
        text: {
            shouldShow: false,
            color: '',
        },
        lockState: {
            shouldShow: false,
            locked: false,
        },
    });
    this.fabricCanvas.on('mouse:down', evt => {
        if (evt.button === 3) {
            const target = this.fabricCanvas.findTarget(evt);
            if (target) {
                this.fabricCanvas.discardActiveObject().setActiveObject(target).requestRenderAll();
            } else {
                this.fabricCanvas.discardActiveObject().requestRenderAll();
            }
        }
    });
    const dealWithSelection = () => {
        const target = this.fabricCanvas.getActiveObject();
        if (!target || (target.type === 'activeSelection' && target.getObjects().length === 0)) {
            return;
        }
        this.selection = true;
        this.selectionParams.stroke = this.getStrokeOfSelected();
        this.selectionParams.fill = this.getFillOfSelected();
        this.selectionParams.text = this.getTextSettingsOfSelected();
        this.selectionParams.lockState = this.getLockedOfSelected();
        let draggable = this.draggable, scaleable = this.scaleable;
        for (const object of this.fabricCanvas.getActiveObjects()) {
            if (object.locked || object.is_cursor) {
                draggable = scaleable = false;
                break;
            }
        }
        target.lockMovementX = target.lockMovementY = !draggable;
        target.lockUniScaling = !scaleable;
        target.setControlsVisibility({
            mt: scaleable, mb: scaleable, ml: scaleable, mr: scaleable,
            tl: scaleable, tr: scaleable, bl: scaleable, br: scaleable,
            mtr: scaleable,
        });
        const bound = target.getBoundingRect();
        this.showContextMenuAtPosition(
            Math.round(bound.left + (bound.width - this.contextMenuSettings.component.offsetWidth) / 2),
            Math.round(bound.top - this.contextMenuSettings.component.offsetHeight - 55),
        );
    };
    this.fabricCanvas.on('selection:created', evt => {
        dealWithSelection();
    });
    this.fabricCanvas.on('selection:updated', evt => {
        this.hideContextMenu();
        dealWithSelection();
    });
    this.fabricCanvas.on('selection:cleared', evt => {
        this.selection = false;
        this.hideContextMenu();
    });
    // we should not show context menu while dragging, scaling or rotating object
    this.fabricCanvas.on('object:moving', evt => {
        if (this.contextMenuSettings.show) {
            this.hideContextMenu();
        }
    });
    this.fabricCanvas.on('object:scaling', evt => {
        if (this.contextMenuSettings.show) {
            this.hideContextMenu();
        }
    });
    this.fabricCanvas.on('object:rotating', evt => {
        if (this.contextMenuSettings.show) {
            this.hideContextMenu();
        }
    });
    // when mouse up, we should show context menu over the selected object
    this.fabricCanvas.on('mouse:up', evt => {
        dealWithSelection();
    });
}

export function setContextMenuComponent(contextMenu) {
    this.contextMenuSettings.component = contextMenu;
}

export function hideContextMenuByClickOutside() {
    if (!this.selection) {
        this.hideContextMenu();
    }
}

export function showContextMenuAtPosition(x, y) {
    this.contextMenuSettings.show = true;
    this.contextMenuSettings.component.style.left = x + 'px';
    this.contextMenuSettings.component.style.top = y + 'px';
    if (this.contextMenuSettings.component.classList.contains('ytm-hidden')) {
        this.contextMenuSettings.component.classList.remove('ytm-hidden');
    }
    if (!this.contextMenuSettings.component.classList.contains('ytm-visible')) {
        this.contextMenuSettings.component.classList.add('ytm-visible');
    }
}

export function hideContextMenu() {
    this.contextMenuSettings.show = false;
    if (this.contextMenuSettings.component.classList.contains('ytm-visible')) {
        this.contextMenuSettings.component.classList.remove('ytm-visible');
    }
    if (!this.contextMenuSettings.component.classList.contains('ytm-hidden')) {
        this.contextMenuSettings.component.classList.add('ytm-hidden');
    }
}
