export function getTextSettingsOfSelected() {
    const objects = this.fabricCanvas.getActiveObjects();
    if (objects.length === 0) {
        return {
            shouldShow: false,
            color: '',
        };
    }
    let textSettings = {
        shouldShow: true,
        color: '',
    };
    for (const [idx, object] of objects.entries()) {
        if (object.type !== 'i-text') {
            textSettings.shouldShow = false;
            textSettings.color = '';
            break;
        }
        if (idx === 0) {
            textSettings.color = object.fill;
        } else if (object.fill !== textSettings.color) {
            textSettings.color = '';
            break;
        }
    }
    return textSettings;
}
