export function deleteActiveObjects() {
    let objects = this.getObjectsFromTarget(this.fabricCanvas.getActiveObject());
    let uuids = objects.map(obj => obj.uuid);
    if (uuids.length !== 0) {
        this.sendObjectDeletion(uuids);
        for (let uuid of uuids) {
            this.removeObject(uuid);
        }
    }
}
