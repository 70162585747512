import {fabric} from "fabric";
import {v4 as uuidv4} from "uuid";

export function setObjectsStorage() {
    this.objectsByUUID = {};
    this.objectsJSON = {};
}

export function addObjects(objects) {
    return new Promise((resolve, reject) => {
        fabric.util.enlivenObjects(objects, (objects) => {
            for (let obj of objects) {
                if (this.objectsByUUID.hasOwnProperty(obj.uuid)) {
                    continue;
                }
                obj = this.denormalizeObject(obj);
                this.registerObjectOnBoard(obj);
                this.fabricCanvas.add(obj);
            }
            this.fabricCanvas.requestRenderAll();
            resolve();
        });
    });
}

export function registerObjectOnBoard(object) {
    this.extendToObjectMethod(object);
    if (!object.uuid) {
        object.set({uuid: uuidv4()});
    }
    object.set({
        selectable: this.selectable,
        noScaleCache: false,
    });
    this.setLockStatus(object, !!object.locked);
    this.objectsByUUID[object.uuid] = object;
    this.objectsJSON[object.uuid] = JSON.stringify(object);
}

export function removeObject(uuid) {
    if (!this.objectsByUUID.hasOwnProperty(uuid)) {
        return;
    }
    const activeObjectsUUIDs = this.fabricCanvas.getActiveObjects().map(obj => obj.uuid);
    this.fabricCanvas.discardActiveObject();
    this.fabricCanvas.remove(this.objectsByUUID[uuid]);
    delete this.objectsByUUID[uuid];
    delete this.objectsJSON[uuid];
    const activeObjects = activeObjectsUUIDs.map(uuid => this.objectsByUUID[uuid]).filter(obj => !!obj);
    if (activeObjects.length !== 0) {
        this.fabricCanvas.setActiveObject(new fabric.ActiveSelection(activeObjects, {canvas: this.fabricCanvas}));
    }
    this.fabricCanvas.requestRenderAll();
}
