import {fabric} from "fabric";
import {startSelecting, stopSelecting} from "@/components/MaterialsV2/BoardCellV2/canvas/modes/selecting";
import {startDrawing, stopDrawing} from "@/components/MaterialsV2/BoardCellV2/canvas/modes/drawing";
import {stopPreviousMode} from "@/components/MaterialsV2/BoardCellV2/canvas/modes/modes";
import {
    performErase,
    performEraseOnPoint,
    startErasing,
    stopErasing
} from "@/components/MaterialsV2/BoardCellV2/canvas/modes/erasing";
import {
    addObjects,
    registerObjectOnBoard, removeObject,
    setObjectsStorage
} from "@/components/MaterialsV2/BoardCellV2/canvas/objects_storage/objects_storage";
import {
    makeObjectsDraggable, makeObjectsScaleable,
    makeObjectsSelectable, makeObjectsUndraggable, makeObjectsUnscaleable,
    makeObjectsUnselectable
} from "@/components/MaterialsV2/BoardCellV2/canvas/settings/settings";
import {
    extendToObjectMethod,
    denormalizeObject,
    normalizeManyObjects,
    normalizeObject, getObjectsFromTarget
} from "@/components/MaterialsV2/BoardCellV2/canvas/objects_storage/util";
import {
    handleMouseMove,
    handleObjectAddition,
    handleObjectDeletion,
    handleObjectTransform
} from "@/components/MaterialsV2/BoardCellV2/canvas/websocket/handlers";
import {HistoryStorage} from "@/util/history-storage";
import {
    registerMouseMoveSender, registerObjectAdditionSender, registerObjectDeletionSender, registerObjectTransformSender,
    sendMouseMove,
    sendObjectAddition,
    sendObjectDeletion,
    sendObjectTransform
} from "@/components/MaterialsV2/BoardCellV2/canvas/websocket/senders";
import {
    drawingLine,
    drawLine,
    finishDrawLine, scaleLine,
    startLine,
    stopLine
} from "@/components/MaterialsV2/BoardCellV2/canvas/modes/line";
import {
    drawingRectangle,
    drawRectangle, finishDrawRectangle, scaleRectangle,
    startRectangle,
    stopRectangle
} from "@/components/MaterialsV2/BoardCellV2/canvas/modes/rectangle";
import {
    drawCircle,
    drawingCircle,
    finishDrawCircle, scaleCircle,
    startCircle,
    stopCircle
} from "@/components/MaterialsV2/BoardCellV2/canvas/modes/circle";
import {
    performRedo,
    performUndo, redoAddition, redoDeletion, redoTransform,
    undoAddition, undoDeletion,
    undoTransform
} from "@/components/MaterialsV2/BoardCellV2/canvas/operations/undo_redo";
import {deleteActiveObjects} from "@/components/MaterialsV2/BoardCellV2/canvas/operations/remove_selected";
import {copyActiveObjects, pasteObjects} from "@/components/MaterialsV2/BoardCellV2/canvas/operations/copy_paste";
import {
    getRandomColor,
    makeCursor,
    removeCursor,
    renderCursor,
    renderCursors
} from "@/components/MaterialsV2/BoardCellV2/canvas/cursors/cursors";
import {addImageByURL} from "@/components/MaterialsV2/BoardCellV2/canvas/modes/image";
import {startText, stopText} from "@/components/MaterialsV2/BoardCellV2/canvas/modes/text";
import {
    hideContextMenu, hideContextMenuByClickOutside,
    setContextMenu,
    setContextMenuComponent,
    showContextMenuAtPosition,
} from "@/components/MaterialsV2/BoardCellV2/canvas/context_menu/context_menu";
import {
    changeBrushWidth, getBrushWidth, getColor,
    setColor,
    setDefaultCommonSettings
} from "@/components/MaterialsV2/BoardCellV2/canvas/instruments/common_settings";
import {getStrokeOfSelected, setStrokeColorToSelected} from "@/components/MaterialsV2/BoardCellV2/canvas/context_menu/stroke";
import {getFillOfSelected, setFillColorToSelected} from "@/components/MaterialsV2/BoardCellV2/canvas/context_menu/fill";
import {getTextSettingsOfSelected} from "@/components/MaterialsV2/BoardCellV2/canvas/context_menu/text";
import {
    getLockedOfSelected,
    setLockedToSelected,
    setLockStatus
} from "@/components/MaterialsV2/BoardCellV2/canvas/context_menu/locking";

export class YTMCanvas {
    constructor(boardId, cellId) {
        this.boardId = boardId;
        this.cellId = cellId;
    }

    setCanvas() {
        this.fabricCanvas = new fabric.Canvas(this.cellId + '_new', {
            backgroundColor: '#f8f8f8',
            renderOnAddRemove: false,
            objectCaching: false,
            targetFindTolerance: 3,
            preserveObjectStacking: true,
            fireRightClick: true,
            stopContextMenu: true,
        });
        this.scalingNow = false;
        this.fabricCanvas.on('object:scaling', opt => {
            this.scalingNow = true;
            switch (opt.target.type) {
                case 'line':
                    this.scaleLine(opt.target);
                    break;
                case 'rect':
                    this.scaleRectangle(opt.target);
                    break;
                case 'ellipse':
                    this.scaleCircle(opt.target);
                    break;
            }
        });
        this.rotatingNow = false;
        this.fabricCanvas.on('object:rotating', opt => {
            this.rotatingNow = true;
        })
        this.fabricCanvas.on('mouse:up', opt => {
            this.scalingNow = false;
            this.rotatingNow = false;
        });
        this.fabricCanvas.on('object:modified', opt => {
            let objects = this.getObjectsFromTarget(opt.target);
            if (objects.length !== 0) {
                this.sendObjectTransform(objects);
            }
        });
        this.usersOnBoard = {};
        this.prevMouse = {x: 0, y: 0};
        this.fabricCanvas.on('mouse:move', (evt) => {
            let curMouse = { x: evt.pointer.x, y: evt.pointer.y };
            if (curMouse.x !== this.prevMouse.x || curMouse.y !== this.prevMouse.y) {
                this.prevMouse = curMouse;
            }
        });
        let lastSent = { x: 0, y: 0 };
        this.triggerSendMouseMove = setInterval(() => {
            if (this.prevMouse.x !== lastSent.x || this.prevMouse.y !== lastSent.y) {
                this.sendMouseMove(this.prevMouse.x - 5, this.prevMouse.y - 5);
                lastSent = this.prevMouse;
            }
        }, 100);
        this.setDefaultCommonSettings();
        this.setContextMenu();
        this.prevEraser = {x: null, y: null};
        this.setObjectsStorage();
        this.history = new HistoryStorage(30);
        this.startSelecting();
    }

    destroy() {
        clearInterval(this.triggerSendMouseMove);
    }
}

YTMCanvas.prototype.onObjectAddition = registerObjectAdditionSender;
YTMCanvas.prototype.onObjectTransform = registerObjectTransformSender;
YTMCanvas.prototype.onObjectDeletion = registerObjectDeletionSender;
YTMCanvas.prototype.onMouseMove = registerMouseMoveSender;

YTMCanvas.prototype.setDefaultCommonSettings = setDefaultCommonSettings;
YTMCanvas.prototype.changeBrushWidth = changeBrushWidth;
YTMCanvas.prototype.setColor = setColor;
YTMCanvas.prototype.getBrushWidth = getBrushWidth;
YTMCanvas.prototype.getColor = getColor;

YTMCanvas.prototype.setContextMenu = setContextMenu;
YTMCanvas.prototype.setContextMenuComponent = setContextMenuComponent;
YTMCanvas.prototype.showContextMenuAtPosition = showContextMenuAtPosition;
YTMCanvas.prototype.hideContextMenu = hideContextMenu;
YTMCanvas.prototype.hideContextMenuByClickOutside = hideContextMenuByClickOutside;
YTMCanvas.prototype.getStrokeOfSelected = getStrokeOfSelected;
YTMCanvas.prototype.setStrokeColorToSelected = setStrokeColorToSelected;
YTMCanvas.prototype.getFillOfSelected = getFillOfSelected;
YTMCanvas.prototype.setFillColorToSelected = setFillColorToSelected;
YTMCanvas.prototype.getTextSettingsOfSelected = getTextSettingsOfSelected;
YTMCanvas.prototype.getLockedOfSelected = getLockedOfSelected;
YTMCanvas.prototype.setLockedToSelected = setLockedToSelected;
YTMCanvas.prototype.setLockStatus = setLockStatus;

YTMCanvas.prototype.setObjectsStorage = setObjectsStorage;

YTMCanvas.prototype.handleObjectAddition = handleObjectAddition;
YTMCanvas.prototype.handleObjectTransform = handleObjectTransform;
YTMCanvas.prototype.handleObjectDeletion = handleObjectDeletion;
YTMCanvas.prototype.handleMouseMove = handleMouseMove;

YTMCanvas.prototype.sendObjectAddition = sendObjectAddition;
YTMCanvas.prototype.sendObjectTransform = sendObjectTransform;
YTMCanvas.prototype.sendObjectDeletion = sendObjectDeletion;
YTMCanvas.prototype.sendMouseMove = sendMouseMove;

YTMCanvas.prototype.makeCursor = makeCursor;
YTMCanvas.prototype.removeCursor = removeCursor;
YTMCanvas.prototype.renderCursors = renderCursors;
YTMCanvas.prototype.renderCursor = renderCursor;
YTMCanvas.prototype.getRandomColor = getRandomColor;

YTMCanvas.prototype.addObjects = addObjects;
YTMCanvas.prototype.registerObjectOnBoard = registerObjectOnBoard;
YTMCanvas.prototype.removeObject = removeObject;

YTMCanvas.prototype.extendToObjectMethod = extendToObjectMethod;

YTMCanvas.prototype.getObjectsFromTarget = getObjectsFromTarget;

YTMCanvas.prototype.normalizeObject = normalizeObject;
YTMCanvas.prototype.normalizeManyObjects = normalizeManyObjects;
YTMCanvas.prototype.denormalizeObject = denormalizeObject;

YTMCanvas.prototype.makeObjectsUnselectable = makeObjectsUnselectable;
YTMCanvas.prototype.makeObjectsSelectable = makeObjectsSelectable;
YTMCanvas.prototype.makeObjectsUndraggable = makeObjectsUndraggable;
YTMCanvas.prototype.makeObjectsDraggable = makeObjectsDraggable;
YTMCanvas.prototype.makeObjectsUnscaleable = makeObjectsUnscaleable;
YTMCanvas.prototype.makeObjectsScaleable = makeObjectsScaleable;

YTMCanvas.prototype.stopPreviousMode = stopPreviousMode;

YTMCanvas.prototype.startSelecting = startSelecting;
YTMCanvas.prototype.stopSelecting = stopSelecting;

YTMCanvas.prototype.startDrawing = startDrawing;
YTMCanvas.prototype.stopDrawing = stopDrawing;

YTMCanvas.prototype.startErasing = startErasing;
YTMCanvas.prototype.stopErasing = stopErasing;
YTMCanvas.prototype.performErase = performErase;
YTMCanvas.prototype.performEraseOnPoint = performEraseOnPoint;

YTMCanvas.prototype.startLine = startLine;
YTMCanvas.prototype.stopLine = stopLine;
YTMCanvas.prototype.drawLine = drawLine;
YTMCanvas.prototype.drawingLine = drawingLine;
YTMCanvas.prototype.finishDrawLine = finishDrawLine;
YTMCanvas.prototype.scaleLine = scaleLine;

YTMCanvas.prototype.startRectangle = startRectangle;
YTMCanvas.prototype.stopRectangle = stopRectangle;
YTMCanvas.prototype.drawRectangle = drawRectangle;
YTMCanvas.prototype.drawingRectangle = drawingRectangle;
YTMCanvas.prototype.finishDrawRectangle = finishDrawRectangle;
YTMCanvas.prototype.scaleRectangle = scaleRectangle;

YTMCanvas.prototype.startCircle = startCircle;
YTMCanvas.prototype.stopCircle = stopCircle;
YTMCanvas.prototype.drawCircle = drawCircle;
YTMCanvas.prototype.drawingCircle = drawingCircle;
YTMCanvas.prototype.finishDrawCircle = finishDrawCircle;
YTMCanvas.prototype.scaleCircle = scaleCircle;

YTMCanvas.prototype.startText = startText;
YTMCanvas.prototype.stopText = stopText;

YTMCanvas.prototype.addImageByURL = addImageByURL;

YTMCanvas.prototype.performUndo = performUndo;
YTMCanvas.prototype.performRedo = performRedo;
YTMCanvas.prototype.undoAddition = undoAddition;
YTMCanvas.prototype.undoTransform = undoTransform;
YTMCanvas.prototype.undoDeletion = undoDeletion;
YTMCanvas.prototype.redoAddition = redoAddition;
YTMCanvas.prototype.redoTransform = redoTransform;
YTMCanvas.prototype.redoDeletion = redoDeletion;

YTMCanvas.prototype.deleteActiveObjects = deleteActiveObjects;

YTMCanvas.prototype.copyActiveObjects = copyActiveObjects;
YTMCanvas.prototype.pasteObjects = pasteObjects;
