import {MODE_DRAWING} from "@/components/MaterialsV2/BoardCellV2/canvas/const";

export function startDrawing() {
    if (this.commonSettings.mode) {
        this.stopPreviousMode();
    }
    this.commonSettings.mode = MODE_DRAWING;
    this.fabricCanvas.isDrawingMode = true;
    this.fabricCanvas.freeDrawingBrush.width = this.getBrushWidth();
    this.fabricCanvas.freeDrawingBrush.color = this.getColor();
    this.fabricCanvas.freeDrawingBrush.decimate = Math.min(3, 2.25 * this.getBrushWidth());
    this.fabricCanvas.on('path:created', (evt) => {
        this.registerObjectOnBoard(evt.path);
        this.sendObjectAddition(evt.path);
    });
}

export function stopDrawing(nextChosen = false) {
    this.fabricCanvas.isDrawingMode = false;
    this.fabricCanvas.__eventListeners['path:created'].pop();
    this.commonSettings.mode = null;
    if (!nextChosen) {
        this.startSelecting();
    }
}
