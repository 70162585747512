import {fabric} from "fabric";
import {isMobile} from "@/util/is-mobile";
import {MODE_LINE} from "@/components/MaterialsV2/BoardCellV2/canvas/const";

export function drawLine(x, y) {
    this.makeObjectsUndraggable();
    let line = new fabric.Line([x, y, x, y], {
        fill: this.getColor(),
        stroke: this.getColor(),
        strokeWidth: this.getBrushWidth(),
        noScaleCache: false,
    });
    this.fabricCanvas.add(line);
    this.registerObjectOnBoard(line);
    this.curLineId = line.uuid;
}

export function drawingLine(line, x, y) {
    if (line.x2 === x && line.y2 === y) {
        return;
    }
    line.set({x2: x, y2: y});
    line.setCoords();
    this.fabricCanvas.requestRenderAll();
}

export function finishDrawLine() {
    if (!this.curLineId) {
        return;
    }
    const line = this.objectsByUUID[this.curLineId];
    if (line.x1 !== line.x2 || line.y1 !== line.y2) {
        this.fabricCanvas.setActiveObject(line);
        this.sendObjectAddition(line);
    } else {
        this.fabricCanvas.remove(line);
    }
    this.fabricCanvas.requestRenderAll();
    this.curLineId = null;
    this.makeObjectsDraggable();
}

export function startLine() {
    if (this.commonSettings.mode) {
        this.stopPreviousMode();
    }
    this.commonSettings.mode = MODE_LINE;
    this.fabricCanvas.defaultCursor = this.fabricCanvas.hoverCursor = 'crosshair';
    if (!isMobile()) {
        this.fabricCanvas.on('mouse:down', evt => { this.drawLine(evt.pointer.x, evt.pointer.y) });
        this.fabricCanvas.on('mouse:move', evt => {
            if (this.scalingNow || this.rotatingNow) {
                this.finishDrawLine();
                return;
            }
            if (this.curLineId) {
                const line = this.objectsByUUID[this.curLineId];
                this.drawingLine(line, evt.pointer.x, evt.pointer.y, evt.e.shiftKey);
            }
        });
        this.fabricCanvas.on('mouse:up', () => { this.finishDrawLine() });
    } else {
        this.fabricCanvas.on('touch:drag', (evt) => {
            if (this.scalingNow || this.rotatingNow) {
                this.finishDrawLine();
                return;
            }
            if (evt.e.hasOwnProperty('changedTouches')) {
                this.finishDrawLine();
            } else if (evt.self.x !== undefined && evt.self.y !== undefined) {
                if (!this.curLineId) {
                    this.drawLine(evt.self.x, evt.self.y);
                }
                const line = this.objectsByUUID[this.curLineId];
                this.drawingLine(line, evt.self.x, evt.self.y);
            }
        });
    }
}

export function stopLine(nextChosen = false) {
    this.fabricCanvas.defaultCursor = this.fabricCanvas.hoverCursor = 'default';
    if (!isMobile()) {
        this.fabricCanvas.__eventListeners['mouse:down'].pop();
        this.fabricCanvas.__eventListeners['mouse:move'].pop();
        this.fabricCanvas.__eventListeners['mouse:up'].pop();
    } else {
        this.fabricCanvas.__eventListeners['touch:drag'].pop();
    }
    this.commonSettings.mode = null;
    if (!nextChosen) {
        this.startSelecting();
    }
}

export function scaleLine(line) {
    line.set({
        width: line.width * line.scaleX,
        height: line.height * line.scaleY,
        scaleX: 1,
        scaleY: 1,
    });
}
