<template>
    <div>
        <Board
            :boardId="roomId"
            :cellId="cellId"
            :active="isActive"
            :ar="material.ar"
            :objects="objects"
            :collaboration="true"
            ref="board"
            @ready="$emit('ready')"
            @newAR="sendNewAR"
            @onObjectAddition="onObjectAddition"
            @onObjectTransform="onObjectTransform"
            @onObjectDeletion="onObjectDeletion"
            @onMouseMove="onMouseMove"
        />
    </div>
</template>

<script>
import Board from "@/components/redesign/Board/Board.vue";
import axios from "axios";
import {SERVICE_MATERIALS_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
    name: 'BoardCell',
    components: {Board},
    props: {
        roomId: {
            type: String,
            required: true,
        },
        cellId: {
            type: String,
            required: true,
        },
        material: {
            type: Object,
            required: true,
        },
        objects: {
            type: Array,
            default: [],
        },
        isActive: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        sendNewAR(newAR) {
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.put(
                    SERVICE_MATERIALS_URI + '/materials',
                    {ar: newAR, id: this.cellId},
                    {
                        headers: authHeader(),
                        params: {
                            type: 'board',
                        },
                    },
                );
            });
        },
        onObjectAddition(object) {
            this.$store.dispatch('ws/sendObjectAddition', {
                fId: `${this.roomId}:${this.cellId}`,
                o: object,
            });
        },
        onObjectTransform(objects) {
            this.$store.dispatch('ws/sendObjectTransform', {
                fId: `${this.roomId}:${this.cellId}`,
                o: objects,
            });
        },
        onObjectDeletion(uuids) {
            this.$store.dispatch('ws/sendObjectDeletion', {
                fId: `${this.roomId}:${this.cellId}`,
                o: uuids,
            });
        },
        onMouseMove({x, y}) {
            this.$store.dispatch('ws/sendMouseMove', {
                fId: `${this.roomId}:${this.cellId}`,
                x: x,
                y: y,
            });
        },
        updateMaterial(payload) {
            this.$refs.board.updateMaterial(payload);
        },
    },
};
</script>

<style scoped>

</style>