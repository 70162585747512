import {mergeAttributes, Node, VueNodeViewRenderer} from "@tiptap/vue-3";
import YTMFormula from "@/components/MaterialsV2/CollabText/YTMFormula/YTMFormula.vue";

export default Node.create({
    name: 'YTMFormula',
    group: 'inline',
    inline: true,
    selectable: false,
    addAttributes() {
        return {
            formula: {
                type: String,
                default: 'a^2+b^2=c^2',
            },
        };
    },
    parseHTML() {
        return [{ tag: 'Formula' }];
    },
    renderHTML({ HTMLAttributes }) {
        return ['Formula', mergeAttributes(HTMLAttributes)];
    },
    addNodeView() {
        return VueNodeViewRenderer(YTMFormula);
    },
});
