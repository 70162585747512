import {fabric} from "fabric";

export function handleObjectAddition(data) {
    this.addObjects([data.object]);
}

export function handleObjectTransform(data) {
    fabric.util.enlivenObjects(data.objects, objects => {
        const activeObjectsUUIDs = this.fabricCanvas.getActiveObjects().map(obj => obj.uuid);
        this.fabricCanvas.discardActiveObject();
        for (let obj of objects) {
            const objectOnBoard = this.objectsByUUID[obj.uuid];
            if (!objectOnBoard) {
                this.registerObjectOnBoard(obj);
                this.fabricCanvas.add(obj);
                continue;
            }
            obj = this.denormalizeObject(obj);
            const objId = this.fabricCanvas.getObjects().indexOf(objectOnBoard);
            this.fabricCanvas.remove(objectOnBoard)
            this.fabricCanvas.insertAt(obj, objId);
            this.registerObjectOnBoard(obj);
        }
        const activeObjects = activeObjectsUUIDs.map(uuid => this.objectsByUUID[uuid]).filter(obj => !!obj);
        if (activeObjects.length !== 0) {
            this.fabricCanvas.setActiveObject(new fabric.ActiveSelection(activeObjects, {canvas: this.fabricCanvas}));
        }
        this.fabricCanvas.requestRenderAll();
    });
}

export function handleObjectDeletion(data) {
    for (const uuid of data.uuids) {
        this.removeObject(uuid);
    }
}

export function handleMouseMove(data) {
    const username = data.username;
    if (!this.usersOnBoard.hasOwnProperty(username)) {
        this.makeCursor(this.getRandomColor(), username);
    }
    this.usersOnBoard[username].x = data.x * this.fabricCanvas.width;
    this.usersOnBoard[username].y = data.y * this.fabricCanvas.width;
    this.renderCursors(username);
}
