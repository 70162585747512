<template>
  <div
      style="position: relative; margin-bottom: 0.5rem"
      @keydown.meta.z.exact="performUndo"
      @keydown.ctrl.z.exact="performUndo"
      @keydown.meta.shift.z.exact="performRedo"
      @keydown.ctrl.shift.z.exact="performRedo"
  >
    <Codemirror
        v-model:value="code"
        :options="cmOptions"
        border
        placeholder=""
        ref="editor"
    />
    <select v-model="cmOptions.mode" style="position: absolute; right: 4px; top: 4px" @change="onLangChange">
      <option value="text/plain">Текст</option>
      <option value="text/x-c++src">C/C++</option>
      <option value="text/x-csharp">C#</option>
      <option value="text/x-go">Go</option>
      <option value="text/x-java">Java</option>
      <option value="text/javascript">Javascript</option>
      <option value="text/x-python">Python</option>
      <option value="text/x-sql">SQL</option>
    </select>
  </div>
</template>

<script>
import * as Y from 'yjs';
import { CodemirrorBinding } from 'y-codemirror';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/go/go';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/python/python';
import 'codemirror/mode/sql/sql';
import 'codemirror/lib/codemirror.css';
import Codemirror from 'codemirror-editor-vue3';
import {inject} from "vue";
import axios from "axios";
import {SERVICE_MATERIALS_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
  name: 'CollabCode',
  components: {Codemirror},
  props: {
    material: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isProviderReady() {
      return this.$store.getters['crdt_ws/socketReady'];
    },
  },
  watch: {
    isProviderReady: {
      handler(new_val, old_val) {
        if (this.binding === null) {
          this.makeBinding();
        }
      },
    },
    areMaterialsActive: {
      handler(newVal) {
        this.$refs.editor.cminstance.setOption('readOnly', newVal ? false : 'nocursor');
      },
    },
  },
  data() {
    return {
      code: '',
      cmOptions: {
        mode: 'text/plain',
        lineNumbers: true,
        smartIndent: true,
        indentUnit: 4,
        foldGutter: true,
        styleActiveLine: true,
        autoCloseBrackets: true,
        readOnly: this.areMaterialsActive ? false : 'nocursor',
      },
      ytext: null,
      yUndoManager: null,
      binding: null,
    };
  },
  setup() {
    const ydoc = inject('ydoc');
    const areMaterialsActive = inject('areMaterialsActive');
    return {ydoc, areMaterialsActive};
  },
  methods: {
    onLangChange() {
      this.$refs.editor.cminstance.setOption('mode', this.cmOptions.mode);
      this.$store.dispatch('auth/autoLogin').then(() => {
        axios.put(
            SERVICE_MATERIALS_URI + '/materials',
            {lang: this.cmOptions.mode, id: this.material.id},
            {
              headers: authHeader(),
              params: {
                type: 'code',
              },
            },
        );
      });
    },
    setEditor() {
      this.ytext = this.ydoc.getText(this.material.id);
      this.yUndoManager = new Y.UndoManager(this.ytext, {
        trackedOrigins: new Set([]),
      });
      if (this.isProviderReady) {
        this.makeBinding();
      } else {
        this.$emit('ready');
      }
    },
    makeBinding() {
      const editor = this.$refs.editor.cminstance;
      this.binding = new CodemirrorBinding(this.ytext, editor, this.$store.getters['crdt_ws/awareness'], {yUndoManager: this.yUndoManager});
      this.binding.awareness.setLocalStateField('user', { name: this.$store.getters['auth/username'] });
    },
    updateMaterial(new_material) {
      this.cmOptions.mode = new_material.material.lang;
      this.$refs.editor.cminstance.setOption('mode', this.cmOptions.mode);
    },
    performUndo() {
      this.$refs.editor.cminstance.undo();
    },
    performRedo() {
      this.$refs.editor.cminstance.redo();
    },
    deleteContent() {
      this.$refs.editor.cminstance.setValue('');
      this.$refs.editor.cminstance.clearHistory();
    },
  },
  mounted() {
    this.cmOptions.mode = this.material.material.lang || 'text/plain';
    this.setEditor();
  },
};
</script>

<style>
.CodeMirror * {
  font-family: "JetBrains Mono", monospace;
}
.remote-caret {
  position: relative;
  border-left: 2px solid black;
  margin-left: -1px;
  margin-right: -1px;
  box-sizing: border-box;
}
.remote-caret > div {
  position: absolute;
  top: -0.8em;
  left: -1px;
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  background-color: #ffc107;
  color: #0D0D0D;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
  transition: opacity .3s ease-in-out;
}
.remote-caret.hide-name > div {
  transition-delay: .7s;
  opacity: 0;
}
.remote-caret:hover > div {
  opacity: 1;
  transition-delay: 0s;
}
</style>