<template>
  <NodeViewWrapper class="vue-component" style="display: inline-block">
    <div v-if="formula != null">
      <div v-if="editable" style="position: relative">
        <math-field id="mathfield" virtual-keyboard-mode=manual @input="fromKeyboard">
          {{formula}}
        </math-field>
        <div class="ytm-formula-editor">
          <TextArea id="latex" v-model="formula" style="width: 100%"></TextArea>
          <button class="ytm-formula-button" @click="showPreview" style="margin-right: 0.5rem">Отобразить</button>
          <button class="ytm-formula-button" @click="update">Сохранить</button>
        </div>
      </div>
      <div v-else style="position: relative">
        <math-field ref="mfro">{{formula}}</math-field>
        <div @click="setEditable" style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; cursor: text"/>
      </div>
    </div>
  </NodeViewWrapper>
</template>

<script>
import {NodeViewWrapper, nodeViewProps} from '@tiptap/vue-3';
import "mathlive/dist/mathlive.min.mjs";
import "mathlive/dist/mathlive-fonts.css";
import "mathlive/dist/mathlive-static.css";
import 'katex/dist/katex.min.css';
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg';
import TextArea from "@/components/common/Form/TextArea.vue";

export default {
  name: 'YTMFormula',
  components: {
      TextArea, NodeViewWrapper,
  },
  props: nodeViewProps,
  data() {
    return {
      formula: null,
      editable: false,
      remixiconUrl,
    };
  },
  watch: {
    'node.attrs.formula'(new_val) {
      this.formula = null;
      this.$nextTick(() => {
        this.formula = new_val;
        this.save();
      });
    },
  },
  methods: {
    fromKeyboard(ev) {
      this.formula = ev.target.value;
    },
    showPreview() {
      const mf = document.getElementById('mathfield');
      mf.setValue(
          this.formula,
          {suppressChangeNotifications: true},
      );
    },
    save() {
      this.editable = false;
      this.$nextTick(() => {
        this.$refs.mfro.setOptions({ readOnly: true });
      });
    },
    update() {
      this.updateAttributes({
        formula: this.formula,
      });
      this.save();
    },
    setEditable() { this.editable = (this.editor.isEditable ? true : this.editable); },
  },
  mounted() {
    this.formula = this.node.attrs.formula;
    this.$nextTick(() => {
      this.$refs.mfro.setOptions({ readOnly: true });
    });
  },
};
</script>

<style scoped>
.ytm-formula-button {
  background: #f0f0f0;
  transition: all .4s;
  font-weight: normal;
  padding: 2px 5px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.ytm-formula-button:hover, .ytm-formula-button:focus {
  background: #f0f0f0;
  border: 1px solid black;
  box-shadow: none;
}

.ytm-formula-editor {
  position: absolute;
  z-index: 100;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 400px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 8px 5px;
}
</style>