import {MODE_SELECTING} from "@/components/MaterialsV2/BoardCellV2/canvas/const";

export function startSelecting() {
    if (this.commonSettings.mode) {
        this.stopPreviousMode();
    }
    this.commonSettings.mode = MODE_SELECTING;
    this.fabricCanvas.defaultCursor = 'default';
    this.fabricCanvas.hoverCursor = 'pointer';
    this.fabricCanvas.selection = true;
    this.makeObjectsSelectable();
    this.fabricCanvas.on('mouse:down', () => { this.fabricCanvas.defaultCursor = 'default'; });
}

export function stopSelecting(nextChosen = false) {
    this.fabricCanvas.selection = false;
    this.fabricCanvas.__eventListeners['mouse:down'].pop();
    this.commonSettings.mode = null;
    if (!nextChosen) {
        this.startSelecting();
    }
}
