<template>
  <div>
    <template v-for="(item, index) in items">
      <div class="divider" v-if="item.type === 'divider'" :key="`divider${index}`"/>
      <MenuItem v-else :key="index" v-bind="item"/>
    </template>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue'

export default {
  name: 'MenuBar',
  components: {
    MenuItem,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          icon: 'bold',
          title: 'Жирный текст',
          action: () => { this.editor.chain().focus().toggleBold().run(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'italic',
          title: 'Курсив',
          action: () => { this.editor.chain().focus().toggleItalic().run(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'strikethrough',
          title: 'Зачеркнутый текст',
          action: () => {this.editor.chain().focus().toggleStrike().run(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('strike'),
        },
        {
          icon: 'code-view',
          title: 'Код',
          action: () => {this.editor.chain().focus().toggleCode().run(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('code'),
        },
        {
          icon: 'link',
          title: 'Ссылка',
          action: () => {this.setLink(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('link'),
        },
        {
          icon: 'link-unlink',
          title: 'Отменить ссылку',
          action: () => this.editor.chain().focus().unsetLink().run(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'h-1',
          title: 'Заголовок 1 уровня',
          action: () => {this.editor.chain().focus().toggleHeading({ level: 1 }).run(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('heading', { level: 1 }),
        },
        {
          icon: 'h-2',
          title: 'Заголовок 2 уровня',
          action: () => {this.editor.chain().focus().toggleHeading({ level: 2 }).run(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('heading', { level: 2 }),
        },
        {
          icon: 'paragraph',
          title: 'Параграф',
          action: () => {this.editor.chain().focus().setParagraph().run(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('paragraph'),
        },
        {
          icon: 'list-unordered',
          title: 'Маркированный список',
          action: () => {this.editor.chain().focus().toggleBulletList().run(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'list-ordered',
          title: 'Упорядоченный список',
          action: () => {this.editor.chain().focus().toggleOrderedList().run(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          icon: 'list-check-2',
          title: 'Список задач',
          action: () => {this.editor.chain().focus().toggleTaskList().run(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('taskList'),
        },
        // {
        //   icon: 'code-box-line',
        //   title: 'Блок кода',
        //   action: () => this.editor.chain().focus().toggleCodeBlock().run(),
        //   isActive: () => this.editor.isActive('codeBlock'),
        // },
        {
          icon: 'functions',
          title: 'Математическая формула',
          action: () => {this.editor.chain().focus().insertContent('<Formula/>').run(); this.editor.setEditable(true)},
          isActive: () => this.editor.isActive('Formula'),
        },
        {
          type: 'divider',
        },
        // {
        //   icon: 'double-quotes-l',
        //   title: 'Цитата',
        //   action: () => this.editor.chain().focus().toggleBlockquote().run(),
        //   isActive: () => this.editor.isActive('blockquote'),
        // },
        // {
        //   icon: 'separator',
        //   title: 'Горизонтальная черта',
        //   action: () => this.editor.chain().focus().setHorizontalRule().run(),
        // },
        {
          icon: 'arrow-go-back-line',
          title: 'Отменить',
          action: () => {this.editor.chain().focus().undo().run(); this.editor.setEditable(true)},
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Вернуть отменённое',
          action: () => {this.editor.chain().focus().redo().run(); this.editor.setEditable(true)},
        },
      ],
    };
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);
      if (url === null) {
        return;
      }
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
      } else {
        this.editor.chain().focus().extendMarkRange('link').setLink({href: url, target: '_blank'}).run();
      }
    },
  },
};
</script>

<style>
.divider {
  width: 2px;
  height: 1.25rem;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}
</style>