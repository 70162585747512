import axios from "axios";
import {SERVICE_BOARD_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export class BoardsManager {
    constructor(board_id, store, component) {
        this.board_id = board_id;
        this.store = store;
        this.cells = {};
        this.cell_by_user = {};
        this.objects_by_cell = {};
        this.store.dispatch('auth/autoLogin').then(() => {
            axios.get(
                SERVICE_BOARD_URI + '/board',
                {
                    params: {board_id: this.board_id},
                    headers: authHeader(),
                },
            ).then(resp => {
                const objects = resp.data.payload.objects;
                for (const object of objects) {
                    if (!this.objects_by_cell.hasOwnProperty(object.cId)) {
                        this.objects_by_cell[object.cId] = [];
                    }
                    this.objects_by_cell[object.cId].push(object.content);
                }
                component.board_fetched = true;
            });
        });
    }

    onNewSocketMessage(t, a, payload) {
        const cellId = payload.fId.split(':', 2)[1];
        if (!cellId) {
            return;
        }
        switch (t) {
            case 3002:
                if (!a || a === localStorage.getItem('username')) {
                    return;
                }
                if (this.cell_by_user.hasOwnProperty(a) && this.cell_by_user[a] !== cellId) {
                    const old_cellId = this.cell_by_user[a];
                    this.cells[old_cellId].removeCursor(a);
                }
                this.cells[cellId].handleMouseMove({
                    username: a,
                    x: payload.x,
                    y: payload.y,
                });
                this.cell_by_user[a] = cellId;
                break;
            case 3003:
                this.cells[cellId].handleObjectAddition({object: payload.o});
                break;
            case 3004:
                this.cells[cellId].handleObjectTransform({objects: payload.o});
                break;
            case 3005:
                this.cells[cellId].handleObjectDeletion({uuids: payload.o});
                break;
            default:
                console.error('unrecognized socket message', payload);
        }
    }

    registerBoardCell(cell) {
        this.cells[cell.cellId] = cell;
    }

    unregisterBoardCell(cell) {
        delete this.cells[cell.cellId];
    }
}