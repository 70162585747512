import {fabric} from "fabric";
import {isMobile} from "@/util/is-mobile";
import {MODE_RECTANGLE} from "@/components/MaterialsV2/BoardCellV2/canvas/const";

export function drawRectangle(x, y) {
    this.makeObjectsUndraggable();
    let rect = new fabric.Rect({
        left: x,
        top: y,
        start_x: x,
        start_y: y,
        width: this.getBrushWidth(),
        height: this.getBrushWidth(),
        fill: 'transparent',
        stroke: this.getColor(),
        strokeWidth: this.getBrushWidth(),
        noScaleCache: false,
    });
    this.fabricCanvas.add(rect);
    this.registerObjectOnBoard(rect);
    this.curRectId = rect.uuid;
}

export function drawingRectangle(rect, x, y, shiftKey = false) {
    let width = Math.abs(x - rect.start_x);
    let height = Math.abs(y - rect.start_y);
    if (shiftKey) {
        width = height = Math.min(width, height);
    }
    rect.set({
        left: Math.min(x, rect.start_x),
        top: Math.min(y, rect.start_y),
        width: width,
        height: height,
    });
    rect.setCoords();
    this.fabricCanvas.requestRenderAll();
}

export function finishDrawRectangle() {
    if (!this.curRectId) {
        return;
    }
    const rect = this.objectsByUUID[this.curRectId];
    if (rect.width > this.getBrushWidth() + 0.001 || rect.height > this.getBrushWidth() + 0.001) {
        this.fabricCanvas.setActiveObject(rect);
        this.sendObjectAddition(rect);
    } else {
        this.fabricCanvas.remove(rect);
    }
    this.fabricCanvas.requestRenderAll();
    this.curRectId = null;
    this.makeObjectsDraggable();
}

export function startRectangle() {
    if (this.commonSettings.mode) {
        this.stopPreviousMode();
    }
    this.commonSettings.mode = MODE_RECTANGLE;
    this.fabricCanvas.defaultCursor = this.fabricCanvas.hoverCursor = 'crosshair';
    if (!isMobile()) {
        this.fabricCanvas.on('mouse:down', evt => { this.drawRectangle(evt.pointer.x, evt.pointer.y) });
        this.fabricCanvas.on('mouse:move', evt => {
            if (this.scalingNow || this.rotatingNow) {
                this.finishDrawRectangle();
                return;
            }
            if (this.curRectId) {
                const rect = this.objectsByUUID[this.curRectId];
                this.drawingRectangle(rect, evt.pointer.x, evt.pointer.y, evt.e.shiftKey);
            }
        });
        this.fabricCanvas.on('mouse:up', () => { this.finishDrawRectangle() });
    } else {
        this.fabricCanvas.on('touch:drag', evt => {
            if (this.scalingNow || this.rotatingNow) {
                this.finishDrawRectangle();
                return;
            }
            if (evt.e.hasOwnProperty('changedTouches')) {
                this.finishDrawRectangle();
            } else if (evt.self.x !== undefined && evt.self.y !== undefined) {
                if (!this.curRectId) {
                    this.drawRectangle(evt.self.x, evt.self.y);
                }
                let rect = this.objectsByUUID[this.curRectId];
                this.drawingRectangle(rect, evt.self.x, evt.self.y);
            }
        });
    }
}

export function stopRectangle(nextChosen = false) {
    this.fabricCanvas.defaultCursor = this.fabricCanvas.hoverCursor = 'default';
    if (!isMobile()) {
        this.fabricCanvas.__eventListeners['mouse:down'].pop();
        this.fabricCanvas.__eventListeners['mouse:move'].pop();
        this.fabricCanvas.__eventListeners['mouse:up'].pop();
    } else {
        this.fabricCanvas.__eventListeners['touch:drag'].pop();
    }
    this.commonSettings.mode = null;
    if (!nextChosen) {
        this.startSelecting();
    }
}

export function scaleRectangle(rect) {
    rect.set({
        width: rect.width * rect.scaleX,
        height: rect.height * rect.scaleY,
        scaleX: 1,
        scaleY: 1,
    });
}
